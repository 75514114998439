import React, { Component } from "react";
import { compose } from "recompose";
import { Redirect } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/FormHelperText";
import ListingCard from "../search/ListingCard";

const useStyles = (theme) => ({
  properties: {
    overflow: "auto",
    maxHeight: "70vh",
  },
  userBox: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  avatarUser: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
});

class User extends Component {
  state = {
    name: "",
    password: "",
    firstName: "",
    lastName: "",
    category: "",
  };
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/" />;
    const listings = this.props.listings;
    const userInfo = this.props.userInfo;
    console.log(userInfo);
    const { classes } = this.props;
    if (userInfo) {
      return (
        <Container component="main" className={classes.userBox}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Avatar
                alt="Remy Sharp"
                src="https://source.unsplash.com/random"
                className={classes.avatarUser}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4">
                Bienvenido {userInfo.firstName} {userInfo.lastName}
              </Typography>
              <Button>Editar informacion</Button>
              <Button component={NavLink} to="/crear">
                Crear un listado
              </Button>
              <Button>Ver tus mensajes</Button>
            </Grid>
          </Grid>
          <Typography variant="h4">Tus propiedades</Typography>
          <Box maxHeight="500px" className={classes.properties}>
            <Grid container spacing={2}>
              {listings &&
                listings.map((listing, index) => {
                  return (
                    <Grid item xs={3}>
                      <ListingCard key={"listing" + index} listing={listing} />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Container>
      );
    }
    return <Typography>Cargando...</Typography>;
  }
}

const mapStateToProps = (state) => {
  console.log(state.firestore.data);
  const userInfo = state.firestore.data.userInfo
    ? state.firestore.data.userInfo
    : null;
  const listings = state.firestore.data.listings
    ? state.firestore.ordered.listings
    : null;
  return {
    userInfo: userInfo,
    listings: listings,
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

export default compose(
  withStyles(useStyles, { withTheme: true }),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users",
      doc: props.auth.uid,
      storeAs: "userInfo",
    },
    {
      collection: "propiedad_listing",
      storeAs: "listings",
      where: [["creator", "==", props.auth.uid]],
    },
  ])
)(User);
