import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    secondary: { main: '#0aa6b0', dark:'#055854', light:'#9093ff' },
    primary: { main: '#F9C92C', dark:'#FFA630', light:'#F9C92C' },
    // secondary: { main: '#f58420', dark:'#f26423', light:'#425d9c' },
    info: {main: "#45cafb", dark: "#0099c8", light:"#35c1d1"}
  },
  typography: {
    // fontFamily: "Century'Poppins',Arial,Helvetica,sans-serif"
    fontFamily: 'century-gothic, sans-serif'
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 4,
        height: 7
      },
      bar1Determinate: {
        borderRadius: 4
      },
      colorPrimary: {
        backgroundColor: "#f5f5f5"
      }
    },
    MuiCircularProgress: {
      circle: {
        strokeLinecap: "round",
        strokeWidth: 2.8
      }
    }
  }
})
export default theme