import React, { Component } from "react";
import { Box, Container, withStyles, Typography } from "@material-ui/core";
import { compose } from "redux";
import { fade } from "@material-ui/core/styles";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import SearchCard from "./SearchCard";
import Featured from "./Featured";

const useStyles = (theme) => ({
  bannerBox: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2)), url(https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Flanding.jpg?alt=media&token=b2244df0-479a-498c-a239-28d121081da6)`,
    minHeight: "60vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "16px",
  },
  bannerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  searchCard: {
    position: "relative",
    margin: "auto"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.6),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.8),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

class Landing extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      listingFilters: {
        check_sell: true,
        check_rent: true,
        check_shared: true,
      },
      position: null,
    };
  }

  handleChange = (e) => {
    let target = e.target.id ? e.target.id : e.target.name;
    let parts = target.split(".");
    let value = e.target.value;
    if (parts[0].startsWith("check_")) {
      value = e.target.checked;
    }
    if (parts.length > 1) {
      value = this.state[parts[0]];
      value[parts[1]] = e.target.value;
      if (parts[1].startsWith("check_")) {
        value[parts[1]] = e.target.checked;
      }
    }
    this.setState({
      [parts[0]]: value,
    });
  };

  setCoords = (pos) => {
    console.log(pos);
  }

  render() {
    const { classes, listings } = this.props;
    const { listingFilters } = this.state;
    // const fadeImages = [
    //   "url(https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Fsurgery-1807541_1920.jpg?alt=media&token=4c370a64-47ef-4133-a75d-efc9c528197b)",
    //   "https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Fchair-2589771_1920.jpg?alt=media&token=4d35d9fa-cb55-4afd-bf63-317f5d305806",
    //   "https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Fcoronavirus-4910360_1920.jpg?alt=media&token=07933fa7-1a9c-49fa-aa13-5fa0a564948e",
    //   "https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Fhospital-3098683_1920.jpg?alt=media&token=fdfb248a-b41b-4bbd-bda0-2dfeb1035126",
    //   "https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Flaboratory-2815641_1920.jpg?alt=media&token=d9fc4904-1a23-479e-a53e-30f433936f3b",
    // ];
    return (
      <div>
        <Box className={classes.bannerBox}>
          <div className={classes.searchCard}>
            <SearchCard
              listingFilters={listingFilters}
              handleChange={this.handleChange}
            />
          </div>
        </Box>
        <Container>
          <Typography variant="h4" color="secondary">
            PropMedica es el portal especializado en bienes inmuebles y propiedades para el sector medico en México.
          </Typography>
          <Typography paragraph>
            Aquí encuentras las mejores oportunidades en bienes raíces con fines de uso medico como lo son consultorios, clínicas, espacios compartidos, nuevos proyectos en busca de inversionistas, así como proyectos actualmente en desarrollo. También podrás encontrar oportunidades de inversiones en el sector medico, y nuevas modalidades para la administración de bienes inmuebles y equipamiento medico, todo con la finalidad de facilitarte la investigación y la toma de decisiones. Si tu te desenvuelves en el sector medico ya se como inversionista, medico, o simplemente buscas oportunidades con alto potencial de desarrollo y menos riesgos, este portal será tu mejor amigo.
          </Typography>
          <Featured listings={listings} />
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const listings = state.firestore.data.listings
    ? state.firestore.ordered.listings
    : null;
  return {
    listings: listings,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(useStyles, { withTheme: true }),
  firestoreConnect((props) => [
    {
      collection: "listing_info",
      storeAs: "listings",
      limit: 12,
    },
  ])
)(Landing);
