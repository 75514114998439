import React from "react";
import Slider from "infinite-react-carousel";
import ListingCard from "../search/ListingCard";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";

function Featured({ listings }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const settings = {
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 8000,
    centerPadding: 110,
    gutter: 20,
    slidesPerRow: isMobile? 1:4,
    virtualList: true,
  };
  if (listings) {
    return (
      <div>
        <Slider {...settings}>
          {listings &&
            listings.map((listing, index) => {
              return (
                <Box width="25%" padding="16px" key={index}>
                  <ListingCard key={"listing" + index} listing={listing} />
                </Box>
              );
            })}
        </Slider>
      </div>
    );
  }
  return null;
}

export default Featured;
