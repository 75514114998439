import React, { Component } from "react";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";

import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import Landing from "./components/landing/Landing";
import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/Navbar";
import CreateListing from "./components/listing/CreateListing";
import Listing from "./components/listing/Listing";
import Search from "./components/search/Search";
import User from "./components/user/User";
import theme from "./theme";
import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Protect sha512="FFAC419E16888DAF1081382381F6D5A4550FF3AB7B0604A627D054DB97AB0C2E4FF76C27E980B45D6E254912131E2A7825A32EA7925DE54A148431E52561DC06">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <div className="App">
              <Navbar />
              <div className="Content">
                <Switch>
                  <Route exact path="/" component={Landing} />
                  {/* <Route exact path = '/inicio' component = {Dashboard} /> */}
                  <Route exact path="/miperfil" component={User} />
                  <Route exact path="/crear" component={CreateListing} />
                  <Route exact path="/iniciarsesion" component={SignIn} />
                  <Route exact path="/registrate" component={SignUp} />
                  <Route exact path="/buscar/:place_id" component={Search} />
                  <Route exact path="/buscar" component={Search} />
                  <Route
                    exact
                    path="/listado/:listing_id"
                    component={Listing}
                  />
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </div>
              <Footer />
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </Protect>
    );
  }
}

export default App;
