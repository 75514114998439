import React from "react";
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CreateTypeInfo({
  handleChange,
  typeInfo,
  categories,
  types,
}) {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel id="type-label">Tipo</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={typeInfo.type}
              onChange={handleChange}
              label="Tipo"
              inputProps={{
                name: "typeInfo.type",
              }}
            >
              {types &&
                types.map((type, index) => {
                  return (
                    <MenuItem value={type.id} key={index}>
                      {type.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel id="category-label">Categoria</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={typeInfo.category}
              onChange={handleChange}
              label="Categoria"
              inputProps={{
                name: "typeInfo.category",
              }}
            >
              {categories &&
                categories.map((category, index) => {
                  return (
                    <MenuItem value={category.id} key={index}>
                      {category.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
