import React, { Component } from 'react';

import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { Paper } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import { signIn } from '../../store/actions/authActions';

const useStyles = theme => ({
  root: {
    height: '75vh',
  },
  image: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/propiedades-medicas.appspot.com/o/Public%2FLanding%2Fsurgery-1807541_1920.jpg?alt=media&token=4c370a64-47ef-4133-a75d-efc9c528197b)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    console.log(e.target.name, e.target.value)
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }

  render() {
    const { auth } = this.props;
    if (auth.uid) return <Redirect to='/' />
    const { classes } = this.props;
    const { authError } = this.props;
    const isInvalid = this.state.password === '' || this.state.email === '';
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={5}className={classes.image} />
        <Grid item xs={12} sm={8} md={7} elevation={0}>
          <Grid container>
            <Grid item xs={false} sm={1} md={1} />
            <Grid item xs={12} sm={8} md={9}>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Inicia sesión
                </Typography>
                <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electrónico"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={this.handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Constraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Recuerdame"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isInvalid}
                    className={classes.submit}
                  >
                    Inicia Sesión
                  </Button>
                  {authError ? <Typography variant="body2" color='error'>{authError.message}</Typography> : null}
                  <Grid container>
                    <Grid item xs>
                      {/* <Link href="#" variant="body2">
                ¿Olvidaste tu contraseña?
              </Link> */}
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2" component={NavLink} to='/registrate'>
                        {"¿Aun no tienes cuenta? Registrate"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>

            </Grid>
            <Grid item xs={false} sm={1} md={3} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default compose(withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(SignIn)