import React from 'react'
import { Container, Box, Toolbar, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://medicalmex.com/">
                MedicalMex
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    link: {
        margin: theme.spacing(1),
    },
    footer: {
        borderTop: `2px solid ${theme.palette.divider}`,
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
        marginTop: 'auto',
    },
    footerWrapper: {
        paddingTop: "16px !important",
    }
}));


export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.footerWrapper}>
            <Container maxWidth="md" component="footer" className={classes.footer}>
                <Toolbar variant="dense">
                    <Button href="https://www.medicalmex.com/servicios/" color="inherit">
                        Powered by MedicalMex Medical Marketing Solutions
                </Button> |
                <Button href="https://www.medicalmex.com/privacy-policy/" color="inherit">
                        Privacy Policy
                </Button> |
                <Button href="https://www.medicalmex.com/contact-us/" color="inherit">
                        San Diego Offices
            </Button>
                </Toolbar>
                <Box mt={2}>
                    <Copyright />
                </Box>
            </Container>
        </div>

    )
}