import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function CreateInfoSection({ handleChange, fields }) {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2}>
        {fields &&
          fields.map((field, index) => {
            return (
              <Grid item xs={field.size ? field.size : 12} key={index}>
                {field.type === "upload" ? (<DropzoneArea
                  Icon={PhotoLibraryIcon}
                  dropzoneText={"Arrastra archivos aqui o selecciona"}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  showPreviews={true}
                />) : (
                  field.options ? (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="element-label">{field.name}</InputLabel>
                        <Select
                          labelId="element-label"
                          id="category"
                          required={field.required ? field.required : false}
                          value={field.stateValue}
                          onChange={handleChange}
                          label={field.name}
                          inputProps={{
                            name: field.id,
                          }}
                        >
                          {field.options &&
                            field.options.map((option, index) => {
                              return (
                                <MenuItem value={option.value} key={index}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    ) : field.rows ? (
                      <TextField
                        variant="outlined"
                        fullWidth
                        id={field.id}
                        label={field.name}
                        multiline
                        required={field.required ? field.required : false}
                        rows={field.rows}
                        name={field.id}
                        onChange={handleChange}
                      />
                    ) : (
                      <TextField
                        variant="outlined"
                        fullWidth
                        id={field.id}
                        required={field.required ? field.required : false}
                        label={field.name}
                        name={field.id}
                        onChange={handleChange}
                      />
                    )
                  
                )}
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}

export default CreateInfoSection;
