import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

// Replace this with your own config details
var config = {
    apiKey: "AIzaSyBomg4-ln8QEzrSu2VIRacZGX_V3lD40-k",
    authDomain: "propiedades-medicas.firebaseapp.com",
    projectId: "propiedades-medicas",
    storageBucket: "propiedades-medicas.appspot.com",
    messagingSenderId: "238741570696",
    appId: "1:238741570696:web:1a9601f1e3a1814c184b3d",
    measurementId: "G-NWVZW9MD9H"
};
firebase.initializeApp(config);
firebase.firestore();
firebase.functions();

export default firebase