import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    typography: {
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    }
}));

const options = [
    { id: "consultorio", name: "Consultorio"},
    { id: "clinica", name: "Clinica"},
    { id: "hospital", name: "Hospital"},
    { id: "vivienda_asistida", name: "Vivienda asistida"},
]

export default function FilterButton() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const classes = useStyles();
    const [state, setState] = React.useState({consultorio: true, clinica: true, hospital: true, vivienda_asistida: true, select_all:true});

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        // if (event.target.name === "select_all"){
        //     console.log(event.target.checked)
        //     setState({ ...state, "consultorio": event.target.checked });
        //     setState({ ...state, "clinica": event.target.checked });
        //     setState({ ...state, "hospital": event.target.checked });
        //     setState({ ...state, "vivienda_asistida": event.target.checked });
        // }
    };

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const { select_all } = state;
    return (
        <div >
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.paper} >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Tipos de propiedades</FormLabel>
                                <FormGroup>
                                    {options && options.map((option, index) => {
                                        return (
                                            <FormControlLabel key={"check_"+option.id}
                                                control={<Checkbox checked={state[option.id]? state[option.id]: false} onChange={handleChange} name={option.id} />}
                                                label={option.name}
                                            />
                                        )
                                    })}
                                    <Divider />
                                    <FormControlLabel
                                        control={<Checkbox checked={select_all} onChange={handleChange} name="select_all" />}
                                        label="Seleccionar todo"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Button onClick={handleClick('bottom-start')}>Tipos de propiedades</Button>
        </div>
    );
}
