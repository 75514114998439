import React from "react";
import { connect } from "react-redux";
import { matchPath, NavLink } from "react-router-dom";
import { compose } from "redux";
import {
  AppBar,
  ButtonBase,
  IconButton,
  InputBase,
  Link,
  Box,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import BusinessIcon from '@material-ui/icons/Business';
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    padding: theme.spacing(2,1,1,1),  
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
  },
  toolbar: {
    flexWrap: "nowrap",
  },
  barElement: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  link: {
    height: '30px',
    color: theme.palette.text.primary,
    margin: theme.spacing(1, 1.5),
    letterSpacing: theme.spacing(0.2),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      fontWeight: 1000,
      textDecoration: 'none',
      boxShadow: "inset 0 -5px 0 0 "+theme.palette.primary.main
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    color: "white",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    color: "white",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoText: {
    color: theme.palette.primary.main,
    fontSize: "2.5rem",
  },
  logoUnderText: {
    color: theme.palette.text.secondary,
    marginTop: "-10px"
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const { auth } = props;
  const links = auth.uid ? <SignedInLinks /> : <SignedOutLinks />;

  const isLanding = matchPath("/users", {
    path: "/users/:id",
    exact: true,
    strict: true,
  });

  return (
    <div>
      <AppBar position="static" elevation={0} className={classes.appBar} color='transparent'>
        <Toolbar className={classes.toolbar}>
          <div className={classes.barElement}>
            <div className={classes.sectionMobile}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="primary"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <nav>
              <div className={classes.sectionDesktop}>
                <Link
                  variant="button"
                  component={NavLink}
                  to="/"
                  className={classes.link}
                >
                  Compra
                </Link>
                <Link
                  variant="button"
                  component={NavLink}
                  to="/crear"
                  className={classes.link}
                >
                  Venta
                </Link>
                <Link
                  variant="button"
                  component={NavLink}
                  to="/"
                  className={classes.link}
                >
                  Renta
                </Link>
                <Link
                  variant="button"
                  component={NavLink}
                  to="/"
                  className={classes.link}
                >
                  Invierte
                </Link>
              </div>
            </nav>
          </div>

          {/* Logo */}
          <div className={classes.barElement}>
            <ButtonBase component={NavLink} to="/">
              <Box className={classes.logoBox}>
                <Typography align="center" className={classes.logoText}>
                  <BusinessIcon color="primary" fontSize="large"/><b>Prop</b>Medica
                </Typography>
                <Typography secondary align="center" className={classes.logoUnderText}>
                  by MedicalMex
                </Typography>
              </Box>
            </ButtonBase>
          </div>

          {/* <div className={classes.barElement}>
            {isLanding ? (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            ) : null}

            {links}
          </div> */}
          <div className={classes.barElement}>
            <div className={classes.sectionMobile}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="primary"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <nav>
              <div className={classes.sectionDesktop}>
                <Link
                  variant="button"
                  component={NavLink}
                  to="/crear"
                  className={classes.link}
                >
                  Agrega un listado
                </Link>
                {links}
              </div>
            </nav>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(Navbar);
