import React, { Component } from "react";
import { compose } from "recompose";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { signUp } from "../../store/actions/authActions";
import {
  Avatar,
  Button,
  CssBaseline,
  Typography,
  Container,
  Stepper,
  Step,
  StepContent,
  StepLabel,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { withStyles } from "@material-ui/core/styles";
import CreateTypeInfo from "./listingParts/CreateTypeInfo";
import CreateInfoSection from "./listingParts/CreateInfoSection";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class CreateListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      typeInfo: {
        category: "",
        type: "",
      },
      generalInfo: {},
      constructionInfo: {
        use_state: "",
        construction_state: "",
      },
      finishes: {
        finishes_state: ""
      }
    };
  }

  handleChange = (e) => {
    console.log(e.target.id, e.target.name, e.target.value)
    if (e.target.id) {
      let parts = e.target.id.split(".");

      if (parts.length > 1) {
        let temp = this.state[parts[0]];
        temp[parts[1]] = e.target.value;
        this.setState({
          [parts[0]]: temp,
        });
      } else {
        this.setState({
          [e.target.id]: e.target.value,
        });
      }
    } else if (e.target.name) {
      let parts = e.target.name.split(".");
      if (parts.length > 1) {
        let temp = this.state[parts[0]];
        temp[parts[1]] = e.target.value;
        this.setState({
          [parts[0]]: temp,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    console.log(this.state);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // this.props.signUp(this.state);
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
    // setActiveStep(0);
  };

  render() {
    const { classes, categories, types, filters } = this.props;
    const { typeInfo, activeStep } = this.state;
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AddCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Agrega un listado
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={this.handleSubmit}
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step key="type">
                <StepLabel>Tipo de listado</StepLabel>
                <StepContent>
                  <CreateTypeInfo
                    handleChange={this.handleChange}
                    typeInfo={typeInfo}
                    categories={categories}
                    types={types}
                  />
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}>
                        Regresar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        Siguiente
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              {filters && filters.map((step, index) => (
                <Step key={step.key}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <CreateInfoSection handleChange={this.handleChange} fields={step.fields} />
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                        >
                          Regresar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          className={classes.button}
                          disabled={false}
                        >
                          {activeStep === filters.length
                            ? "Guardar"
                            : "Siguiente"}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </form>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const categories = state.firestore.data.categories
    ? state.firestore.ordered.categories
    : null;
  const types = state.firestore.data.types
    ? state.firestore.ordered.types
    : null;
  const filters = state.firestore.data.filters
    ? state.firestore.ordered.filters
    : null;
  return {
    categories: categories,
    types: types,
    filters: filters,
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds) => dispatch(signUp(creds)),
  };
};

export default compose(
  withStyles(useStyles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "listing_categories",
      storeAs: "categories",
      orderBy: ["order"],
    },
    {
      collection: "listing_filters",
      storeAs: "filters",
      orderBy: ["order"],
    },
    {
      collection: "listing_type",
      storeAs: "types",
      orderBy: ["order"],
    },
  ])
)(CreateListing);
