import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Box, Card, Button } from "@material-ui/core";
import ListingCard from "./ListingCard";
import zIndex from "@material-ui/core/styles/zIndex";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    height: "100%",
    backgroundColor: theme.palette.common.Paper,
  },
  bar: {
    zIndex: 11,
    backgroundColor: theme.palette.common.Paper,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

function ResultBar(props) {
  const { listings } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.bar}>
        <Typography variant="h5"> Resultados de busqueda</Typography>
        <Box overflow="auto" maxHeight="70vh">
          {listings &&
            listings.map((listing, index) => {
              return <ListingCard key={"listing" + index} listing={listing} />;
            })}
        </Box>
      </Box>
    </div>
  );
}

export default ResultBar;
