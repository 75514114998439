import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { firestoreConnect } from "react-redux-firebase";
import { withStyles, Grid } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import FilterBar from "./FilterBar";
import Map from "./Map";
import ResultBar from "./ResultBar";
// import geocodePlaceId from './../general/Geocoder'

const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
  },
  searchCard: {
    width: "30%",
    position: "relative",
    margin: "auto",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.main, 0.6),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.8),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

class Search extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      geometry: null,
      listingInfo: {
        sell: true,
        rent: true,
        category: "",
        shared: true,
      },
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const lat = query.get("lat") ? Number(query.get("lat")): null;
    const lon = query.get("lon") ? Number(query.get("lon")): null;
    this.setState({ geometry: { lat: lat, lon: lon }, listingInfo:{sell:this.props.location.state} });
  }

  render() {
    console.log(this.state);
    const geometry = this.state.geometry;
    const { classes, listings } = this.props;
    return (
      <div>
        <FilterBar />

        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Map geometry={geometry} listings={listings} />
          </Grid>
          <Grid item xs={4}>
            <ResultBar listings={listings} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const listings = state.firestore.data.listings
    ? state.firestore.ordered.listings
    : null;
  return {
    listings: listings,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(useStyles, { withTheme: true }),
  firestoreConnect((props) => [
    {
      collection: "listing_info",
      storeAs: "listings",
    },
  ])
)(Search);
