import React from "react";
import { NavLink } from "react-router-dom";
import { Button, makeStyles, fade, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  link: {
    height: '40px',
    color: theme.palette.text.primary,
    margin: theme.spacing(1, 1.5),
    letterSpacing: theme.spacing(0.2),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      fontWeight: 1000,
      textDecoration: 'none',
      boxShadow: "inset 0 -5px 0 0 " + theme.palette.primary.main
    },
  },
  linkButton: {
    // color: "white",
    borderColor: "white",
    backgroundColor: fade(theme.palette.primary.main, 0.5),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    letterSpacing: theme.spacing(0.2),
  },
}));

function SignedOutLinks(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link
        variant="button"
        component={NavLink}
        to="/iniciarsesion"
        className={classes.link}
      >Iniciar Sesión</Link>
      <Button
        className={classes.linkButton}
        type="button"
        variant="outlined"
        component={NavLink}
        to="/registrate"
      >
        Registrate
      </Button>
    </div>
  );
}

export default SignedOutLinks;
