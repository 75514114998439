import React from 'react';
import { NavLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, makeStyles, Box, Card, CardMedia, CardActions, Button, CardContent, ButtonBase, CircularProgress, Paper } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
      card: {
        // height: '350px'
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      },
      footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
      },
}));

const ListingCard = ({ listing }) => {
    const classes = useStyles();
    if (listing) {
        return (
            <Card className={classes.card}>
                <CardMedia
                    className={classes.cardMedia}
                    image={listing.images[0]}
                    title="Image title"
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {listing.name}
                    </Typography>
                    <Typography>
                        {listing.address}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" component={NavLink} to={'/listado/'+listing.id}>
                        Ver mas informacion
                    </Button>
                </CardActions>
            </Card >
        )
    }
    return (
        <div>
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={140} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </div>
    )
}

export default ListingCard