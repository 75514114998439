import React from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { fade } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  searchCard: {
    // position: 'relative',
    // margin: 'auto'
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.common.transparent,
    backgroundColor: fade(theme.palette.common.white, 0.8),
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function LocationSearch(props) {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(null);
  const [coords, setCoords] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState(["current_location"]);
  const loaded = React.useRef(false);
  console.log(value);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBXltw_T0i-aJYwAdqbXPZLIC-mfm280OA&libraries=places&sensor=false&language=es-MX",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : ["current_location"]);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: "mx" } },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          console.log(newOptions);

          setOptions(["current_location"].concat(newOptions));
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  if (redirect) {
    let searchProps = null;
    if (value.place_id) {
      searchProps = `?place_id=${value.place_id}`;
    } else if (coords) {
      searchProps = `?lat=${coords.lat}&lon=${coords.lng}`;
    }
    console.log(searchProps);
    if (searchProps) {
      return (
        <Redirect
          to={{
            pathname: "/buscar",
            search: searchProps,
            state: props,
          }}
        />
      );
    }
  }
  return (
    <Autocomplete
      id="google-map-demo"
      noOptionsText={"Sin resultados..."}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        console.log(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          setRedirect("/buscar/" + newValue.place_id);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Buscar..."
          variant="outlined"
          className={classes.search}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon color="primary"/>
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        if (option === "current_location") {
          return (
            <Grid
              container
              alignItems="center"
              spacing={2}
              onClick={() => {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    setCoords({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    });
                    setRedirect(true);
                  },
                  () => null
                );
              }}
            >
              <Grid item>
                <LocationSearchingIcon
                  className={classes.icon}
                  color="primary"
                />
              </Grid>
              <Grid item xs>
                <Typography color="primary">
                  {" "}
                  Buscar en mi ubicacion actual{" "}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
