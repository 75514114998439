import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import mapStyles from "./mapStyles";
import { IconButton } from "@material-ui/core";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const libraries = ["places"];
const mapContainerStyle = {
  height: "75vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

export default function Map({ geometry, listings }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  const center = {
    lat: geometry && geometry.lat ? geometry.lat : null,
    lng: geometry && geometry.lon ? geometry.lon : null,
  };

  const onMapClick = React.useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    console.log({ lat, lng });
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
      <Locate panTo={panTo} setMarkers={setMarkers} />
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        {listings &&
          listings.map((marker) => (
            <Marker
              key={`${marker.geometry.location.lat}-${marker.geometry.location.lng}`}
              position={{
                lat: marker.geometry.location.lat,
                lng: marker.geometry.location.lng,
              }}
              onClick={() => {
                setSelected(marker);
              }}
            />
          ))}
      </GoogleMap>
    </div>
  );
}

function Locate({ panTo, setMarkers }) {
  return (
    <IconButton
      className="locate"
      style={{
        position: "absolute",
        top: "9rem",
        left: "1rem",
        zIndex: 100,
      }}
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setMarkers((current) => [
              ...current,
              {
                geometry: {
                  location: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                },
              },
            ]);
          },
          () => null
        );
      }}
    >
      <MyLocationIcon />
    </IconButton>
  );
}
