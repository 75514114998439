import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Carousel from "react-material-ui-carousel";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  ListItemIcon, ListItem,
  TextField, Box, Chip, List, ListItemText
} from "@material-ui/core";
import Featured from "../landing/Featured";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  imgCarrousel: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "80vh"
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(2),
    height: "80vh",
    width: '100%',
    overflow: 'auto',
  },
  imageList: {
    width: 500,
    height: 450,
    // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
}));

function Listing(props) {
  const classes = useStyles();
  //   const listing = this.props.listing;
  const { listing, creator, filters, categories, types, listings } = props;
  console.log(filters);
  if (listing) {
    return (
      <Container >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Carousel>
              {listing.images && listing.images.map((image, index) => (
                <img
                  key={"image" + index}
                  className={classes.imgCarrousel}
                  src={image}
                  alt="images"
                />
              ))}
            </Carousel>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.root}>
              <Typography variant="h4">{listing.name}</Typography>
              <Chip style={{ margin: "5px" }} color="primary" label={categories && categories[listing.category] ? categories[listing.category].name : listing.category} />
              <Chip color="secondary" label={types && types[listing.type] ? types[listing.type].name : listing.type} />
              <Typography component="h4" variant="h6" color="textPrimary">
                Introducción
              </Typography>
              <Typography paragraph>{listing.intro}</Typography>
              <Typography component="h4" variant="h6" color="textPrimary">
                Descripcion
              </Typography>
              <Typography paragraph>{listing.description}</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <List>
                    {filters && filters.map((filter, index) => (
                      <ListItem>
                        <ListItemText primary={filter.label} />
                      </ListItem>
                    ))}
                  </List>




                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader
                      title="FirstName LastName"
                      subheader="Title"
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      action={
                        <Avatar
                          alt="Remy Sharp"
                          src="https://source.unsplash.com/random"
                          className={classes.avatarUser}
                        />
                      }
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography variant="subtitle2" color="textPrimary">
                          Envia tus datos a el encardado de esta propiedad
                        </Typography>
                      </div>
                      <ul>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Telefono"
                          id="telefono"
                          autoComplete="phone" />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Correo electronico"
                          id="email"
                          autoComplete="email" />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          multiline
                          rows={3}
                          label="Notas"
                          id="telefono" />
                      </ul>
                    </CardContent>
                    <CardActions>
                      <Button fullWidth variant="outlined" color="primary">
                        Contactar
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Featured listings={listings} />
      </Container>
    );
  }
  return <Typography>Cargando...</Typography>;
}

const mapStateToProps = (state) => {
  console.log(state.firestore.data.creator);
  const listing = state.firestore.data.listing
    ? state.firestore.data.listing
    : null;
  const creator = state.firestore.data.creator
    ? state.firestore.data.creator
    : null;
  const filters = state.firestore.data.filters
    ? state.firestore.ordered.filters
    : null;
  const categories = state.firestore.data.categories
    ? state.firestore.data.categories
    : null;
  const types = state.firestore.data.types
    ? state.firestore.data.types
    : null;
    const listings = state.firestore.data.listings
    ? state.firestore.ordered.listings
    : null;
  return {
    listings: listings,
    listing: listing,
    creator: creator,
    filters: filters,
    types: types,
    categories: categories,
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    var collection = [
      {
        collection: "listing_info",
        storeAs: "listings",
        limit: 12,
      },
      {
        collection: "listing_info",
        doc: props.match.params.listing_id,
        storeAs: "listing",
      },
      {
        collection: "listing_categories",
        storeAs: "categories",
        orderBy: ["order"],
      },
      {
        collection: "listing_filters",
        storeAs: "filters",
        orderBy: ["order"],
      },
      {
        collection: "listing_type",
        storeAs: "types",
        orderBy: ["order"],
      },
    ];
    // if (creatorRef) {
    //   collection.push({
    //     collection: "users",
    //     doc: creatorRef.creator,
    //     storeAs: "creator",
    //   });
    // }
    return collection;
  })
)(Listing);
