import {
  Grid,
  Typography, makeStyles, Button, Box, ButtonBase, Container
} from "@material-ui/core";
import React from "react";
import { matchPath, NavLink } from "react-router-dom";
import LocationSearch from "../general/locationSearch";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontWeight: 700,
    color: theme.palette.common.white,
    textShadow: "2px 3px 5px #404040"
  },
  link: {
    height: '30px',
    color: theme.palette.primary.main,
    textShadow: "2px 3px 5px #404040",
    fontWeight: 1000,
    letterSpacing: theme.spacing(0.2),
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  buttonBox: {
    ...theme.typography.button,
    margin: theme.spacing(1, 1.5),
    padding: theme.spacing(.5),
    height: "40px",
    "&:hover": {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
      boxShadow: "inset 0 -5px 0 0 " + theme.palette.primary.dark
    },
  },
  button: {
    height: '100%',
  }
}));


const searchOptions = [{ key: "consultorios", label: "Consultorios", path: "/" },
{ key: "clinica", label: "Clinicas", path: "/" },
{ key: "hospital", label: "Hospital", path: "/" },
{ key: "compartido", label: "Espacios Compartidos", path: "/" },
{ key: "vivienda", label: "Vivienda de Asistencia", path: "/" },
{ key: "recuperacion", label: "Cuartos Recuperación Post-Quirúrgicas", path: "/" }]

function SearchCard(props) {
  const classes = useStyles();
  return (
    <Container  maxWidth="md">
      <Grid container spacing={1}>
        <Grid item xs={12}>
           <Typography variant="h3"  align="center" className={classes.headerTitle}>Bienes Inmuebles Médicos en México</Typography>
      <Typography variant="h4"  align="center" className={classes.headerTitle}>Encuentra tu Oportunidad</Typography>
        </Grid>
        <Grid item xs={12}>
          {searchOptions.map((option) => (
            <ButtonBase component={NavLink}
            key={option.key}
            to={option.path}
            className={classes.buttonBox}>
            <Typography className={classes.link}>
              {option.label}
            </Typography>
          </ButtonBase>
          
        ))}
        </Grid>
        

        <Grid item sm={12} md={8}>
          <LocationSearch listingFilters={props.listingFilters} />
        </Grid>
        <Grid item sm={12} md={2} >
          <Button color="primary" variant="contained" fullWidth className={classes.button}>Compra</Button>
        </Grid>
        <Grid item sm={12} md={2} >
          <Button color="primary" variant="contained" fullWidth className={classes.button}>Renta</Button>
        </Grid>
      </Grid>

    </Container>
  );
}

export default SearchCard;
